import React from "react"

import Layout from "../components/Layout"
import StoryblokService from '../utils/storyblok-service'
import ImagesService from '../utils/images-service'
import SEO from "../components/SEO"
import Employee from "../components/Employee"

class EmployeeTemplate extends React.Component {

  constructor(props) {
    super()
    this.props = props

    this.state = {
      story: {
        content: this.props.pageContext.story ? JSON.parse(this.props.pageContext.story.content) : {},
        name: this.props.pageContext.story.name
      },
      slug: this.props.pageContext.slug
    }
  }

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.pageContext.story.full_slug}`)
    return story
  }

  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    if(story.name) this.setState({ headline: story.name })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }

  render() {
    return (
       <Layout location={this.props.location}>
         <SEO 
          title={this.state.story.name}
          lang="de"
          prefix="og: https://ogp.me/ns# website: https://ogp.me/ns/website#"
          description={this.state.story.content.seo.description}
          meta={[{
            property: 'og:type',
            content: 'website'
          }, {
            property: 'og:locale',
            content: "de"
          }, {
            property: 'og:title',
            content: (this.state.story.content.seo.og_title === '' ? this.state.story.name : this.state.story.content.seo.og_title)
          }, {
            property: 'og:description',
            content: (this.state.story.content.seo.og_description === '' ? this.state.story.description : this.state.story.content.seo.og_description)
          }, {
            property: 'og:site_name',
            content: 'TriazPR'
          }, {
            property: 'og:image',
            content: (this.state.story.content.seo.og_image === '' ? null : ImagesService(this.state.story.content.seo.og_image, true, { path: '1920x1080/smart'}))
          }, {
            property: 'og:image:width',
            content: '1920'
          }, {
            property: 'og:image:height',
            content: '1080'
          }, {
            property: 'og:url',
            content: `https://triaz-pr.de/${ this.state.full_slug }`
          }, {
            property: 'twitter:title',
            content: (this.state.story.content.seo.twitter_title === '' ? this.state.story.name : this.state.story.content.seo.twitter_title)
          }, {
            property: 'twitter:description',
            content: (this.state.story.content.seo.twitter_description === '' ? this.state.story.description : this.state.story.content.seo.twitter_description)
          }]}
          structuredData={{
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              'itemListElement': [{
                '@type': 'ListItem',
                'position': 1,
                'name': 'Startseite',
                'item': 'https://triaz-pr.de'
              }, {
                '@type': 'ListItem',
                'position': 2,
                'name': this.state.story.name,
                'item': `https://triaz-pr.de/${ this.state.full_slug }`
              }]
          }}
          />
         <Employee blok={this.state.story.content} />
      </Layout>
    )
  }
}

export default EmployeeTemplate
import React, { Component } from "react"
import moment from "moment"
import ImagesService from "../../utils/images-service"
import StoryblokService from "../../utils/storyblok-service"

import "./styles.scss"
import "moment/min/locales"

class Employee extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        moment.locale('de')

        return (
            <div className="employee-profile">
                <div className="profile-header">
                    <div className="inside">
                        <picture>
                            <source media="(max-width: 740px)" srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '740x555/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '1480x1100/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source media="(max-width: 740px)" srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '740x555/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '1480x1100/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <source srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '1360x600/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '2720x1200/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source srcSet={`${ ImagesService(this.blok.page_picture.filename, true, { path: '1360x600/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.page_picture.filename, true, { path: '2720x1200/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <img loading="lazy" src={ImagesService(this.blok.page_picture.filename, true, { path: '1360x600/smart/filters:format(jpeg):quality(60)'})} alt={this.blok.page_picture.alt} width="1360" height="600" />
                        </picture>
                        <div className="nametag">
                            <div className="name">{this.blok.firstname} {this.blok.lastname}</div><br/>
                            <div className="position">{this.blok.profession}</div>
                            <div className="birthday">* {moment(this.blok.birthday).format('Do MMMM YYYY')} in {this.blok.birthplace}</div>
                            <div className="contact-options">
                                <div className="button-wrapper">
                                    <a href={`mailto:${this.blok.email}`} className="button primary">E-Mail schreiben</a>
                                </div>
                                {this.blok.linkedin_profile.url !== "" &&
                                <a href={this.blok.linkedin_profile.url} className="linkedin-link" title="LinkedIn-Profil öffnen" target="_blank" rel="noreferrer">LinkedIn</a>
                                }
                                {this.blok.xing_profile.url !== "" &&
                                <a href={this.blok.xing_profile.url} className="xing-link" title="XING-Profil öffnen" target="_blank" rel="noreferrer">XING</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className={`profile-details`}>
                        {this.blok.stations.length > 0 &&
                        <div className="stations">
                            <h3>Studium</h3>
                            <ol className="education">
                            {this.blok.education.map((edu, index) => {
                                return (<li key={index}>
                                        <div className="station">
                                            <div className="timeline">
                                                <div className="point" />
                                                <div className="line" />
                                            </div>
                                            <div className="label">
                                                <div className="position">{edu.position}, {edu.employer}</div>
                                                <div className="date">
                                                {edu.end_date !== "" &&
                                                    <div>{`${moment(edu.start_date).format("YYYY")}`} - {`${moment(edu.end_date).format("YYYY")}`}</div>
                                                }
                                                {edu.end_date === "" && edu.start_date !== "" &&
                                                    <div>{`seit ${moment(edu.start_date).format("MMMM YYYY")}`}</div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                </li>)
                            })}
                            </ol>
                            <h3>Stationen</h3>
                            <ol>
                            {this.blok.stations.map((station, index) => {
                                return (<li key={index}>
                                    {station.current &&
                                        <div className="station active">
                                            <div className="timeline">
                                                <div className="point" />
                                                <div className="line" />
                                            </div>
                                            <div className="label">
                                                <div className="position">{station.position}, {station.employer}</div>
                                                <div className="date">
                                                {station.end_date !== "" &&
                                                    <div>{`${moment(station.start_date).format("YYYY")}`} - {`${moment(station.end_date).format("YYYY")}`}</div>
                                                }
                                                {station.end_date === "" &&
                                                    <div>{`seit ${moment(station.start_date).format("MMMM YYYY")}`}</div>
                                                }
                                                </div>
                                                {station.description !== "" &&
                                                <div className="description" dangerouslySetInnerHTML={{ __html: StoryblokService.client.richTextResolver.render(station.description)}} />
                                                }
                                            </div>
                                        </div>
                                    }
                                    {!station.current &&
                                        <div className="station">
                                            <div className="timeline">
                                                <div className="point" />
                                                <div className="line" />
                                            </div>
                                            <div className="label">
                                                <div className="position">{station.position}, {station.employer}</div>
                                                <div className="date">
                                                {station.end_date !== "" &&
                                                    <div>{`${moment(station.start_date).format("YYYY")}`} - {`${moment(station.end_date).format("YYYY")}`}</div>
                                                }
                                                {station.end_date === "" &&
                                                    <div>{`seit ${moment(station.start_date).format("MMMM YYYY")}`}</div>
                                                }
                                                </div>
                                                {station.description !== "" &&
                                                <div className="description" dangerouslySetInnerHTML={{ __html: StoryblokService.client.richTextResolver.render(station.description)}} />
                                                }
                                            </div>
                                        </div>
                                    }
                                </li>)
                            })}
                            </ol>
                        </div>
                        }
                        <div className="successes">
                            <h3>Freiberufliche Tätigkeiten</h3>
                            <ol>
                                {this.blok.freelance_work.map((position, index) => {
                                    return (<li className="success" key={index}>
                                        <div className="name">{position.name}</div>
                                        <div className="description">{position.description}</div>
                                    </li>)
                                })}
                            </ol>
                        </div>
                        <div className="mixed-content">
                            {this.blok.publications.length > 0 &&
                                <div className="publications">
                                    <h3>Publikationen</h3>
                                    <ul>
                                    {this.blok.publications.map((publication, index) => {
                                        return (<li className="publication" key={index}>
                                            <div className="coverImage">
                                                <picture>
                                                    <source media="(max-width: 450px)" srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '450x0/smart/filters:format(webp)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '900x0/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                    <source media="(max-width: 450px)" srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '450x0/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '900x0/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                    <source srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '125x0/smart/filters:format(webp)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '250x0/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                    <source srcSet={`${ ImagesService(publication.cover_image.filename, true, { path: '125x0/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(publication.cover_image.filename, true, { path: '250x0/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                    <img loading="lazy" src={ImagesService(publication.cover_image.filename, true, { path: '125x0/smart/filters:format(jpeg):quality(60)'})} alt={publication.title} width="125"/>
                                                </picture>
                                            </div>
                                            <div className="information">
                                                <h4>{publication.title}</h4>
                                                <div className="publisher-year">{publication.publication_year}, {publication.publisher}</div>
                                                <div className="description">{publication.teaser}</div>
                                                <div className="button-wrapper">
                                                    <a className="button primary" href={publication.buy_link.url} target="_blank" rel="noreferrer">Mehr erfahren</a>
                                                </div>
                                            </div>
                                        </li>)
                                    })}
                                    </ul>
                                </div>
                            }
                            {this.blok.projects.length > 0 &&
                                <div className="foundations">
                                    <h3>Projekte</h3>
                                    <ul>
                                    {this.blok.projects.map((project, index) => {
                                        return (<li className="foundation" key={index}>
                                            <div className="logo">
                                                <picture>
                                                    <source srcSet={`${ ImagesService(project.cover_image.filename, true, { path: '204x114/smart/filters:format(webp)'}) } 1x, ${ ImagesService(project.cover_image.filename, true, { path: '408x228/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                    <source srcSet={`${ ImagesService(project.cover_image.filename, true, { path: '204x114/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(project.cover_image.filename, true, { path: '408x228/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                    <img loading="lazy" src={ImagesService(project.cover_image.filename, true, { path: '204x114/smart/filters:format(jpeg):quality(60)'})} alt={project.title} width="204" height="114" />
                                                </picture>
                                            </div>
                                            <div className="information">
                                                <h4>{project.title}</h4>
                                                {(typeof project.description !== "undefined" && project.description !== null) && 
                                                <p>{project.description}</p>
                                                }
                                                <div className="button-wrapper">
                                                    <a className="button primary" href={project.buy_link.url} target="_blank" rel="noreferrer">Projekt anzeigen</a>
                                                </div>
                                            </div>
                                        </li>)
                                    })}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="awards">
                            <h3>Praktika</h3>
                            <ol>
                                {this.blok.internships.map((internship, index) => {
                                    return (<li className="award" key={index}>
                                        <div className="name">{internship.name}</div>
                                        {internship.year !== "" &&
                                        <div className="years">
                                            <div>
                                                <span>{internship.year}</span>
                                            </div>    
                                        </div>
                                        }
                                        </li>)
                                    })}
                                </ol>
                        </div>
                    </div>
                </div>
            </div>
          )
    }
}

export default Employee